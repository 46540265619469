export const Segment = {
  particulier: 'particulier',
  professional: 'professional',
  professionnel: 'professionnel',
}

export const Tag = {
  individual: 'individual',
  professional: 'professional',
}
