/* eslint-disable @next/next/no-sync-scripts */
import '../styles/globals.css'
// import '../styles/incontact.css'
import '../styles/incontact_dats.css'
import {apiPlugin, storyblokInit} from '@storyblok/react'
import FallbackComponent from 'components/shared/FallbackComponent'
import {SessionContextProvider} from 'context/SessionContextProvider'
import {appWithTranslation, i18n} from 'next-i18next'
import {AppProps} from 'next/app'
import {Open_Sans, Rubik} from 'next/font/google'

import dynamic from 'next/dynamic'
import Head from 'next/head'
import Page404 from 'components/Nodes/Page404'

import {
  ServiceDeliveryPointObject,
  ServiceDeliveryPointType,
} from 'types/api-types'
import {Segment} from 'util/slugHelpers/segment'
import Script from 'next/script'
import Cookies from 'js-cookie'

const ContentPage = dynamic(() => import('../components/Nodes/Contentpage'))

const CookiePolicyPage = dynamic(
  () => import('../components/Nodes/CookiePolicyPage'),
)

const ServicePointLocatorPage = dynamic(
  () =>
    import('../components/Nodes/ServicePointLocator/ServicePointLocatorPage'),
)

const ServicePointDetailPage = dynamic(
  () =>
    import('../components/Nodes/ServicePointDetailPage/ServicePointDetailPage'),
)

const ServicePointOverviewPage = dynamic(
  () => import('../components/Nodes/ServicePointOverviewPage'),
)

const TitleTextImageBlockContainer = dynamic(
  () =>
    import('../components/shared/TitleTextImage/TitleTextImageBlockContainer'),
)
const DataBlock = dynamic(() => import('../components/shared/DataBlock'))
const DownloadAppButtonBlockContainer = dynamic(
  () => import('../components/shared/DownloadAppButtonBlockContainer'),
)

const addCreditCardAsATokenForm = dynamic(
  () => import('../components/Forms/FormCreditCard/FormCreditCard'),
)

const stopChargingPass = dynamic(
  () => import('../components/Forms/FormStopChargingPass/FormStopChargingPass'),
)
const stopChargingPassB2B = dynamic(
  () =>
    import(
      '../components/Forms/FormStopChargingPassB2B/FormStopChargingPassB2B'
    ),
)
const FormStopFuelCardB2B = dynamic(
  () => import('../components/Forms/FormStopFuelCardB2B/FormStopFuelCardB2B'),
)
const wrongCodeForm = dynamic(
  () => import('../components/Forms/FormWrongCode/FormWrongCode'),
)

const blogArticle = dynamic(() => import('../components/Nodes/BlogArticle'))
const ContactPage = dynamic(() => import('../components/Nodes/Contact'))
const section = dynamic(() => import('../components/Content/Section'))
const HighlightContainer = dynamic(
  () => import('../components/Content/Highlight'),
)
const callout = dynamic(() => import('../components/Content/Callout'))
const TextBlockContainer = dynamic(() => import('../components/Content/Text'))
const ImageBlockContainer = dynamic(() => import('../components/Content/Image'))
const HeaderContainer = dynamic(() => import('../components/Content/Header'))
const Columns = dynamic(() => import('../components/Content/Columns'))
const TextImageContainer = dynamic(
  () => import('../components/Content/TextImage'),
)
const CtaBlock = dynamic(() => import('../components/Content/Cta'))
const AccordionContainer = dynamic(
  () => import('../components/Content/Accordion'),
)
const ResourceDownload = dynamic(
  () => import('../components/Content/ResourceDownload'),
)
const RemoveCard = dynamic(
  () => import('../components/Forms/FormRemoveCard/FormRemoveCard'),
)
const VideoContainer = dynamic(() => import('../components/Content/Video'))
const TilesContainer = dynamic(() => import('../components/Content/Tiles'))
const CampaignBannerContainer = dynamic(
  () => import('../components/Content/CampaignBanner'),
)

const addCardToExistingCustomer = dynamic(
  () =>
    import(
      '../components/Forms/FormAddCardExistingCustomer/FormAddCardExistingCustomer'
    ),
)

const RequestChargingPassForIndividual = dynamic(
  () =>
    import(
      '../components/Forms/FormRequestChargingPassForIndividual/FormRequestChargingPassForIndividual'
    ),
)

const newsletterSubscribeForm = dynamic(
  () =>
    import(
      '../components/Forms/FormNewsletterSubscribe/FormNewsletterSubscribe'
    ),
)

const newsletterUnsubscribeForm = dynamic(
  () =>
    import(
      '../components/Forms/FormNewsletterUnsubscribe/FormNewsletterUnsubscribe'
    ),
)

const myDetailsForm = dynamic(
  () => import('../components/Forms/FormMyDetails/FormMyDetails'),
)

const BlogOverviewPage = dynamic(
  () => import('../components/Nodes/BlogOverviewPage/BlogOverviewPage'),
)

const HeroSection = dynamic(() => import('../components/Content/HeroSection'))

const CurrentCustomerBlock = dynamic(
  () => import('../components/Content/CurrentCustomer'),
)

const ButtonBlockContainer = dynamic(
  () => import('../components/shared/ButtonBlockContainer'),
)

const NonStandardList = dynamic(
  () => import('../components/Content/NonStandardList'),
)

const ProductsComparison = dynamic(
  () => import('../components/Content/ProductsComparison'),
)
const ImageCarousel = dynamic(
  () => import('../components/Content/ImageCarousel'),
)

const OneProductContainer = dynamic(
  () => import('../components/Content/OneProduct'),
)

const LocatorContainer = dynamic(() => import('../components/Content/Locator'))
const AlertContainer = dynamic(() => import('../components/Layout/Alert'))

const OfferTiles = dynamic(() => import('../components/Content/OfferTiles'))
const USPs = dynamic(() => import('../components/Content/USPs'))
const HeroImageSection = dynamic(
  () => import('../components/Content/ImageHeroSection'),
)

const CollapsedContent = dynamic(
  () => import('../components/Content/CollapsedContent'),
)

const ContentNoHeaderNoFooter = dynamic(
  () => import('../components/Nodes/ContentNoHeaderNoFooter'),
)
const LinkedPages = dynamic(() => import('../components/Content/LinkedPages'))

const FormFuelingChargingB2bRequest = dynamic(
  () =>
    import(
      '../components/Forms/FormFuelingChargingB2bRequest/FormFuelingChargingB2bRequest'
    ),
)
const RequestAdditionalFuelCard = dynamic(
  () =>
    import(
      '../components/Forms/FormRequestAdditionalFuelCard/FormRequestAdditionalFuelCard'
    ),
)
const Iframe = dynamic(() => import('../components/Content/IFrame'))

const CollapsibleTableContainer = dynamic(
  () => import('../components/Content/CollapsibleTable'),
)

const CollapsibleTableLinkItemContainer = dynamic(
  () => import('../components/Content/CollapsibleTableLinkItem'),
)
const CollapsibleTableAddressItemContainer = dynamic(
  () => import('../components/Content/CollapsibleTableAddressItem'),
)
const components = {
  data_block: DataBlock,
  content_page: ContentPage,
  service_point_locator: ServicePointLocatorPage,
  service_point_detail_page: ServicePointDetailPage,
  service_point_overview_page: ServicePointOverviewPage,
  title_text_image: TitleTextImageBlockContainer,
  download_app_button: DownloadAppButtonBlockContainer,
  add_credit_card_as_a_token_form: addCreditCardAsATokenForm,
  stop_charging_pass: stopChargingPass,
  stop_charging_pass_b2b: stopChargingPassB2B,
  wrong_code: wrongCodeForm,
  newsletter_subscribe: newsletterSubscribeForm,
  newsletter_unsubscribe: newsletterUnsubscribeForm,
  my_details: myDetailsForm,
  blog_article: blogArticle,
  section: section,
  highlight_container: HighlightContainer,
  callout_text: callout,
  text: TextBlockContainer,
  image: ImageBlockContainer,
  header: HeaderContainer,
  columns_block: Columns,
  text_image: TextImageContainer,
  cta_block: CtaBlock,
  resource_download: ResourceDownload,
  remove_card: RemoveCard,
  youtube_video: VideoContainer,
  add_card_to_existing_customer: addCardToExistingCustomer,
  request_charging_pass_for_individuals: RequestChargingPassForIndividual,
  contact_page: ContactPage,
  contact_page_no_header_no_footer: ContactPage,
  blog_overview_page: BlogOverviewPage,
  accordion: AccordionContainer,
  hero_section: HeroSection,
  tiles: TilesContainer,
  campaign_banner: CampaignBannerContainer,
  current_customers_block: CurrentCustomerBlock,
  button: ButtonBlockContainer,
  non_standard_list: NonStandardList,
  products_comparison: ProductsComparison,
  image_carousel: ImageCarousel,
  one_product: OneProductContainer,
  locator: LocatorContainer,
  offer_tiles: OfferTiles,
  alert: AlertContainer,
  usps: USPs,
  hero_image_section: HeroImageSection,
  collapsed_content: CollapsedContent,
  content_page_no_header_no_footer: ContentNoHeaderNoFooter,
  linked_pages: LinkedPages,
  fueling_charging_b2b_request: FormFuelingChargingB2bRequest,
  stop_fuel_card_b2b: FormStopFuelCardB2B,
  request_additional_fuel_card_b2b: RequestAdditionalFuelCard,
  cookie_policy_iframe_page: CookiePolicyPage,
  iframe: Iframe,
  collapsible_table: CollapsibleTableContainer,
  collapsible_table_link_item: CollapsibleTableLinkItemContainer,
  collapsible_table_address_item: CollapsibleTableAddressItemContainer,
  page_404: Page404,
}

storyblokInit({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_DRAFT_KEY!,
  use: [apiPlugin],
  components,
  enableFallbackComponent: true,
  customFallbackComponent: FallbackComponent,
  bridge: true,
})

// Before: export default App;
export default appWithTranslation(App)

export const openSans = Open_Sans({
  weight: ['300', '400', '500', '600', '700'],
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-opensans',
})
export const rubik = Rubik({
  weight: ['400', '500', '700'],
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-rubik',
})
function App({Component, pageProps: {...pageProps}}: AppProps) {
  const lang = i18n?.language

  const constructCanonicalUrl = (locale?: string) => {
    const canonicalUrl = pageProps.story?.full_slug

    if (pageProps.isSDPDetailPage && pageProps.sdpl?.detail_data) {
      function generateSDPCanonical(
        locale: string,
        user_type: string,
        data: ServiceDeliveryPointObject,
      ): string {
        const deliveryPointType = data.type.toUpperCase()
        const deliveryPointId =
          data.chargingLocation?.id ?? data.fuelStation?.id ?? ''

        const slug =
          user_type === Segment.professional && locale === 'fr'
            ? Segment.professionnel
            : user_type

        if (deliveryPointType === ServiceDeliveryPointType.fuel) {
          const fuel_type = locale === 'nl' ? 'tankstation' : 'stationservice'
          const name = data.fuelStation?.name.split(' ').join('_')
          return `${locale}/${slug}/sdp/${fuel_type}_${name}_${deliveryPointId}`
        } else {
          const fuel_type = locale === 'nl' ? 'laadpunt' : 'pointdechargement'
          const city = (data.city ?? '').split(' ').join('_')
          const street = (data?.street ?? '').split(' ').join('_')
          return `${locale}/${slug}/sdp/${fuel_type}_${city}_${street}_${deliveryPointId}`
        }
      }

      // NOTE: SDP detail pages are reflected by a separate individual or professional page
      // in Storyblok. So they are never considered 'common' pages. Therefore, their canonical
      // is always their own URL.
      //
      // In case this must change it would be enough to always point the canonical to the individual
      // page because each page will always has an individul & professional version. There's never a
      // case where only a professional version exists.
      const sdpCanonical = generateSDPCanonical(
        locale ?? lang ?? 'nl',
        Segment.particulier,
        pageProps.sdpl.detail_data,
      )

      return `https://${process.env.NEXT_PUBLIC_DOMAIN}/${sdpCanonical}`
    }

    // Strip query parameters
    let urlWithoutQueryParams = canonicalUrl?.split('?')?.[0]

    if (urlWithoutQueryParams?.endsWith('/')) {
      urlWithoutQueryParams = urlWithoutQueryParams.slice(0, -1)
    }

    // If there are multiple tags ( particulier, individual ) for one and the canonical url is professional
    // Change the canonical to particulier

    if (pageProps.story?.tag_list && pageProps.story.tag_list.length > 1) {
      // Common story. Make sure it points to the particulieren page.
      urlWithoutQueryParams = urlWithoutQueryParams.replace(
        Segment.professional,
        Segment.particulier,
      )
      urlWithoutQueryParams = urlWithoutQueryParams.replace(
        Segment.professionnel,
        Segment.particulier,
      )
    }

    return `https://${process.env.NEXT_PUBLIC_DOMAIN}/${
      lang === 'nl' ? `${lang}/${urlWithoutQueryParams}` : urlWithoutQueryParams
    }`
  }

  const constructHrefLangUrl = (language: 'nl' | 'fr') => {
    if (pageProps.isSDPDetailPage && pageProps.sdpl?.detail_data) {
      let canonical: string = constructCanonicalUrl(language)
      if (language === 'nl') {
        return canonical
          .replace('/fr/', '/nl/')
          .replace(Segment.professionnel, Segment.professional)
      } else {
        return canonical
          .replace('/nl/', '/fr/')
          .replace(Segment.professional, Segment.professionnel)
      }
    } else {
      let translatedSlugs = pageProps.story?.translated_slugs

      let canonicalUrl = translatedSlugs?.find(
        (slug: any) => slug.lang === language,
      )?.path

      if (!canonicalUrl) {
        return ''
      }

      const rmSlashFromEnd = (str: string) => {
        if (str.endsWith('/')) {
          str = str.slice(0, -1)
        }
        return str
      }

      // Strip query parameters
      const urlWithoutQueryParams = canonicalUrl.split('?')?.[0]

      // Strip language
      let components: string[] = urlWithoutQueryParams?.split('/')
      if ((components && components[0] === 'nl') || components[0] === 'fr') {
        return rmSlashFromEnd(components.slice(1).join('/'))
      }

      let urlWithoutSlash = rmSlashFromEnd(urlWithoutQueryParams)

      // Make sure professional/professionnel is correct.
      if (
        language === 'nl' &&
        urlWithoutSlash.includes(Segment.professionnel)
      ) {
        urlWithoutSlash = urlWithoutSlash.replace(
          Segment.professionnel,
          Segment.professional,
        )
      } else if (
        language === 'fr' &&
        urlWithoutSlash.includes(Segment.professional)
      ) {
        urlWithoutSlash = urlWithoutSlash.replace(
          Segment.professional,
          Segment.professionnel,
        )
      }

      return `https://${process.env.NEXT_PUBLIC_DOMAIN}/${language}/${urlWithoutSlash}`
    }
  }

  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${openSans.style.fontFamily};
        }
        #onetrust-consent-sdk {
          font-family: ${openSans.style.fontFamily};
        }
      `}</style>

      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0 maximum-scale=1"
        />
        <link rel="canonical" href={constructCanonicalUrl(lang)} />
        <link rel="alternate" hrefLang="nl" href={constructHrefLangUrl('nl')} />
        <link rel="alternate" hrefLang="fr" href={constructHrefLangUrl('fr')} />
      </Head>

      <Script
        id="xtraFormJs"
        type="text/javascript"
        xtra-client="dats24energy"
        xtra-lang={lang ?? 'nl'}
        xtra-logon-url={`/${lang ?? 'nl'}/particulier/xtra/login`}
        xtra-profile-url="/"
        xtra-token={Cookies.get('dats24energyxtra.accessToken') ?? ''}
        xtra-token-update-url=""
        xtra-token-update-failed-url="/"
        xtra-auto-logon-enabled="true"
        xtra-reload-after-logon-enabled="false"
        src={process.env.NEXT_PUBLIC_XTRA_SRC}
        defer
        onReady={() => {
          if (typeof window !== 'undefined' && window.XTRA) {
            window.XTRA.form.render()
          }
        }}
      ></Script>

      <main className={`${openSans.variable} ${rubik.variable} font-body`}>
        <SessionContextProvider>
          <Component {...pageProps} />
        </SessionContextProvider>
      </main>
    </>
  )
}
